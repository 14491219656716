// src/FileUploader.tsx

import axios from 'axios';
import React, { useCallback, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useDropzone, FileRejection } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import './FileUploader.css'; // CSS for styling

import DraggableList from "../components/DraggableList";

interface FileWithPreview {
  file: File;
  name: string;
  size: string;
  preview: string;
  content: string;
  id: string;
  description: string;
  priority: number;
}

interface FileUploaderProps {
  api: string;
}

const DOCUMENT_NAME = 'documents_list.json';

const FileUploader: React.FC<FileUploaderProps> = ({ api }) => {
  const { user } = useContext(AuthContext);

  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [applying, setApplying] = useState<boolean>(false); // Manage Apply state
  const [applySuccess, setApplySuccess] = useState<boolean>(false); // Apply success state
  const [applyError, setApplyError] = useState<string | null>(null); // Apply error state
  const maxSize = 1024 * 1024; // 1MB

  useEffect(() => {
    const fetchDocuments = async () => {
      if (!user) return;

      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${api}/get_documents?client_id=${user.id}`);
        const documents = response.data[DOCUMENT_NAME];
        const newFiles: FileWithPreview[] = Object.entries(documents).map(([name, info]: [string, any]) => ({
          file: new File([], name), // Placeholder File object
          name,
          size: '0 KB',
          preview: '',
          content: info.content || '',
          id: uuidv4(),
          description: info.description,
          priority: info.priority || 1, // Remove subtraction from priority
        }))
        .sort((a, b) => a.priority - b.priority); // Sort by priority

        setFiles(newFiles);
      } catch (error) {
        console.error('Error fetching documents:', error);
        setError('An error occurred while fetching documents. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, [api, user]);

  // onDrop handler to send POST request immediately upon file upload
  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setError('');
    setSuccess(false);
    setApplySuccess(false);
    setApplyError(null);

    if (fileRejections.length > 0) {
      const rejection = fileRejections[0];
      if (rejection.errors.some((e: any) => e.code === 'file-invalid-type')) {
        setError('Unsupported file type. Only .txt, .log, and .pdf files can be uploaded.');
      }
      if (rejection.errors.some((e: any) => e.code === 'file-too-large')) {
        setError('File size is too large. Maximum size is 1MB.');
      }
      return;
    }

    // Iterate over acceptedFiles for upload processing
    acceptedFiles.forEach(async (file) => {
      // Check if a file with the same name already exists
      const fileNameExists = files.some(f => f.name === file.name);
      if (fileNameExists) {
        setError(`A file named ${file.name} already exists.`);
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('client_id', user ? user.id : '');

      try {
        setUploading(true);
        // POST request to upload file
        await axios.post(`${api}/post_documents_file`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });

        // After successful upload, send GET request to update documents_list.json
        const res = await axios.get(`${api}/get_documents?client_id=${user ? user.id : ''}`);
        const allData = res.data;
        const updatedData = allData[DOCUMENT_NAME] || {};

        // Find the highest priority in the current file list
        const highestPriority = files.reduce((max, file) => file.priority > max ? file.priority : max, 0);

        // Add the newly uploaded file to the state
        const newFile: FileWithPreview = {
          file,
          name: file.name,
          size: (file.size / 1024).toFixed(2) + ' KB',
          preview: '',
          content: updatedData[file.name]?.content || '',
          id: uuidv4(),
          description: updatedData[file.name]?.description || 'Enter a description',
          priority: highestPriority + 1, // Highest priority + 1
        };

        const updatedFiles = [...files, newFile];
        setFiles(updatedFiles);
        setSuccess(true);
        // Automatically call Apply
        handleApply(updatedFiles);
      } catch (err: any) {
        console.error(`File upload failed: ${file.name}`, err);
        setError(`Failed to upload file: ${file.name}`);
      } finally {
        setUploading(false);
      }
    });
  }, [api, user, files]);

  // Modify Apply function to accept the file list as a parameter
  const handleApply = async (filesToApply: FileWithPreview[]) => {
    try {
      setApplying(true);
      setApplyError(null);
      setApplySuccess(false);

      // Define the documents data structure
      const documents = filesToApply.map(file => ({
        name: file.name,
        description: file.description,
        content: file.content,
        priority: file.priority,
      }));

      await axios.post(`${api}/post_policies`, {
        client_id: user ? user.id : '',
        documents
      }, {
        headers: { 'Content-Type': 'application/json' }
      });

      setApplySuccess(true);
      // Adjust the timing of the success message (e.g., remove automatically after 3 seconds)
      setTimeout(() => setApplySuccess(false), 3000);
    } catch (err: any) {
      console.error('Failed to update documents:', err);
      setApplyError('Failed to update documents.');
      // Adjust the timing of the error message (e.g., remove automatically after 5 seconds)
      setTimeout(() => setApplyError(null), 5000);
    } finally {
      setApplying(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/plain': ['.txt', '.log'],
      'application/pdf': ['.pdf']
    },
    maxSize: maxSize,
    multiple: true, // Allow multiple file uploads
  });

  // Cleanup to prevent memory leaks
  useEffect(() => {
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

// Function to remove a file and adjust priorities
  const handleRemoveFile = useCallback((id: string) => {
    const removedFile = files.find(file => file.id === id);
    if (!removedFile) return;

    const updatedFiles = files
      .filter(file => file.id !== id)
      .map(file => {
        if (file.priority > removedFile.priority) {
          return { ...file, priority: file.priority - 1 };
        }
        return file;
      });

    setFiles(updatedFiles);
    handleApply(updatedFiles);
  }, [files]);


  // Function called when the file order is changed via drag and drop
  const handleReorder = useCallback((reorderedItems: FileWithPreview[]) => {
    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      priority: index + 1, // Set priority starting from 1
    }));
    setFiles(updatedItems);
    // Automatically call Apply
    handleApply(updatedItems);
  }, []);

  return (
    <div className="responsive-flex-column">
      <div {...getRootProps({ className: 'dropzone' })} className="dropzone">
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drag files here...</p> :
            <p>Drag and drop (.txt, .pdf, .log) files here or click to upload.</p>
        }
      </div>
      {files.length > 0 && (
        <DraggableList 
          files={files} 
          onReorder={handleReorder} 
          handleRemoveFile={handleRemoveFile} 
        />
      )}
      {error && <p className="error">{error}</p>}
      {uploading && <p>Uploading...</p>}
      {success && <p className="success">File uploaded successfully!</p>}
    </div>
  );
};

export default FileUploader;
