// src/pages/NewLeads.tsx
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { UnReadCountContext } from '../contexts/UnReadCountContext';
import Listing from '../components/Listing_NewLeads';
import Chats from '../components/Chats';
import Summary from '../components/Summary';
import { useSocket } from '../contexts/SocketContext';
import './NewLeads.css';

interface APIProps {
  api: string;
}

export interface Item {
  email: string;
  isRead: boolean;
  isStar?: boolean;
  isCheck?: boolean;
  timestamp: string;
  details?: string;
}

interface Option {
  isStar: boolean;
  isUnRead: boolean;
  retrieve: string;
  limit: number;
}

const ClientsNumLimit = 1000;

const NewLeads: React.FC<APIProps> = ({ api }) => {
  const { user } = useContext(AuthContext);
  const { setUnReadCount } = useContext(UnReadCountContext);
  const socket = useSocket();

  const [items, setItems] = useState<Item[]>([]);
  const [selectedItem, setSelectedItem] = useState<Item>({
    email: '',
    isRead: false,
    isStar: false,
    isCheck: false,
    timestamp: '',
    details: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  // const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [ option, setOption ] = useState<Option>({isStar: false, isUnRead: false, retrieve: "", limit: ClientsNumLimit});
  // 체크된 이메일들을 전역에서 관리할 state
  const [checkedEmails, setCheckedEmails] = useState<string[]>([]);

  useEffect(() => {
    if (!user) return;

    interface CRMResponse {
      error?: string;
      data?: Item[];
    }

    const handleCRMResponse = (response: CRMResponse) => {
      if (response.error) {
        setError(response.error);
      } else if (response.data) {
        setItems(response.data);
        // setUnReadCount(response.data.filter(item => !item.isRead).length);
      }
      setLoading(false);
    };

    const handleConnectionError = (err: any) => {
      setError('WebSocket connection error.');
      setLoading(false);
      console.error('Connection Error:', err);
    };

    setLoading(true);
    setError(null);
    socket.emit('get_crm_list', {
      client_id: user.id,
      option: option
    });

    socket.on('crm_list_response', handleCRMResponse);
    // socket.on('count_unread_crm', (count: number) => {
    //   setUnReadCount(count);
    // });
    socket.on('connect_error', handleConnectionError);

    return () => {
      socket.off('crm_list_response', handleCRMResponse);
      socket.off('connect_error', handleConnectionError);
    };
  }, [user, socket, option]);

  /**
   * 이메일을 받아 해당 아이템을 읽음 처리 & selectedItem으로 지정
   */
  const handleSelectedItem = (email: string) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.email === email ? { ...item, isRead: true } : item
      )
    );
    const foundItem = items.find(item => item.email === email);
    if (foundItem) {
      setSelectedItem({ ...foundItem, isRead: true });
    }

    if (user) {
      socket.emit('update_is_read', {
        client_id: user.id,
        email,
      });
    }
  };

  /**
   * 이메일 & star값을 받아 해당 아이템의 별표 토글
   */
  const handleStarItem = (email: string, star: boolean) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.email === email ? { ...item, isStar: star } : item
      )
    );
    if (socket && user) {
      socket.emit('update_is_star', {
        client_id: user.id,
        email,
        isStar: star,
      });
    }
  };

  /**
   * 체크된 항목들 삭제
   */
  const handleDeleteChecked = (checkedEmails: string[]) => {
    if (!socket || !user) return;
    const previouslyUnReadCount = items.filter(item => checkedEmails.includes(item.email) && !item.isRead).length;
    if (window.confirm('Do you want to delete the selected items?')) {
      socket.emit('update_is_delete', {
        client_id: user.id,
        checkedEmails,
      });
      setItems(prev => prev.filter(item => !checkedEmails.includes(item.email)));
    }
    setUnReadCount(prev => prev - previouslyUnReadCount);
    setCheckedEmails([]);
  };

  /**
   * 체크된 항목들 읽음 처리
   */
  const handleChangeRead = (checkedEmails: string[]) => {
    if (!socket || !user) return;
    const previouslyUnReadCount = items.filter(item => checkedEmails.includes(item.email) && !item.isRead).length;
    socket.emit('update_is_onRead', { client_id: user.id, checkedEmails });
    setItems(prevItems =>
      prevItems.map(item =>
        checkedEmails.includes(item.email) ? { ...item, isRead: true } : item
      )
    );
    setUnReadCount(prev => prev - previouslyUnReadCount);
    setCheckedEmails([]);
  };

  /**
   * 체크된 항목들 안 읽음 처리
   */
  const handleChangeUnRead = (checkedEmails: string[]) => {
    if (!socket || !user) return;
    const previouslyReadCount = items.filter(item => checkedEmails.includes(item.email) && item.isRead).length;
    socket.emit('update_is_unRead', { client_id: user.id, checkedEmails });
    setItems(prevItems =>
      prevItems.map(item =>
        checkedEmails.includes(item.email) ? { ...item, isRead: false } : item
      )
    );
    setUnReadCount(prev => prev + previouslyReadCount);
    setCheckedEmails([]);
  };

  /**
   * 페이지 변경
   */
  // const handleChangePage = async (page: number): Promise<void> => {
  //   setCurrentPage(page);
  //   // 기존 체크를 유지할 것이라면 checkedEmails를 초기화하지 않음
  //   // setCheckedEmails([]); // <-- 만약 페이지 이동 시 체크 해제하고 싶으면 여기에 추가
  // };

  return (
    <div className="responsive-flex">
      <Listing
        items={items}
        loading={loading}
        error={error}
        selectedItem={selectedItem}
        handleSelectedItem={handleSelectedItem}
        handleStarItem={handleStarItem}
        onDeleteChecked={handleDeleteChecked}
        onChangeRead={handleChangeRead}
        onChangeUnRead={handleChangeUnRead}
        checkedEmails={checkedEmails}
        setCheckedEmails={setCheckedEmails}
        option={option}
        setOption={setOption}
        setUnReadCount={setUnReadCount}
      />
      {user && (
      <Chats
        api={api}
        selectedItem={selectedItem.email}
        client_id={user.id}
        isEditModeParents={false}
        
      />
      )}
      {user && (
      <Summary
        api={api}
        selectedItem={selectedItem.email}
        client_id={user.id}
      />
      )}
    </div>
  );
};

export default NewLeads;
