// src/components/Chats.tsx
import React, { useState, useEffect, useRef, useContext } from "react";
import { io, Socket } from "socket.io-client";
import { AuthContext } from "../contexts/AuthContext";
import axios from "axios";
import Papa from "papaparse"; 
import "./Chats.css"; 
import ChatList from "./ChatList"; // 새로운 컴포넌트 임포트

interface ChatProps {
  api: string;
  selectedItem: string;
  client_id: string | null;
  isEditModeParents: boolean;
}

interface ChatMessage {
  timestamp: string;
  channel: string;
  details: string;
  notes: string;
  reasoning?: string;
}

const Chats: React.FC<ChatProps> = ({ api, selectedItem, client_id, isEditModeParents }) => {
  console.log("Chats.tsx selectedItem: ", isEditModeParents);
  const { user } = useContext(AuthContext);

  const [chats, setChats] = useState<ChatMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [newMessage, setNewMessage] = useState<string>("");
  const [isEditMode, setIsEditMode] = useState<boolean>(isEditModeParents);
  const [testMessage, setTestMessage] = useState<string>('');

  // 선택된 채널 (기본값 sms)
  const [selectedChannel, setSelectedChannel] = useState<string>("sms"); 
  // 에이전트 메시지 펼침 상태
  const [expandedMessages, setExpandedMessages] = useState<Set<number>>(new Set());

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // 하단으로 자동 스크롤
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setIsEditMode(false);
  }, [selectedItem]);

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  /**
   * 메시지 전송 함수
   */
  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    const newChat: ChatMessage = {
      timestamp: new Date().toISOString(),
      channel: selectedChannel, 
      details: newMessage,
      notes: "sent", // "sent" → agent가 보낸 메시지
    };
    const updatedChats = [...chats, newChat];
    setChats(updatedChats);
    setNewMessage("");

    try {
      const payload = {
        email: selectedItem,
        channel: selectedChannel,
        message: newMessage,
        client_id: client_id
      };

      // 서버에 메시지 전송
      await axios.post(
        `${api}/send_msg_via_frontend`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // 성공 시 콘솔 찍어보기 (필요에 따라 로직 추가)
      console.log("Message sent successfully.");
    } catch (err) {
      console.error("Error sending message:", err);
      setError("Failed to send message.");
    }
  };
    

  /**
   * 서버로 수정된 chats 배열을 전송
   */
  const postUpdatedChats = async (newChats: ChatMessage[]) => {
    if (!api || !client_id || !selectedItem) return;

    try {
      // 수정된 chats 배열을 서버로 전송
      await axios.post(
        // API 엔드포인트
        `${api}/post_interactions?client_id=${client_id}&email=${selectedItem}`,
        newChats,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("Chats updated successfully on the server.");
    } catch (error) {
      console.error("Error updating chats:", error);
      setError("Failed to update chats.");
    }
  };

  /**
   * 메시지 펼침 토글
   */
  const toggleMessage = (index: number) => {
    setExpandedMessages(prev => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  /**
   * 채널 선택
   */
  const handleChannelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedChannel(e.target.value);
  };

  /**
   * Chat 삭제 기능
   */
  const handleDeleteChat = (index: number) => {
    const updatedChats = [...chats];
    updatedChats.splice(index, 1);
    setChats(updatedChats);

    // 삭제 후 서버에 업데이트된 chats 전송
    postUpdatedChats(updatedChats);
  };

  /**
   * Chat details 수정 기능
   */
  const handleUpdateChatDetails = (index: number, newDetails: string) => {
    const updatedChats = [...chats];
    updatedChats[index].details = newDetails;
    setChats(updatedChats);

    // 수정 후 서버에 업데이트된 chats 전송
    postUpdatedChats(updatedChats);
  };

  /**
   * 선택된 클라이언트의 채팅 히스토리 가져오기
   */
  useEffect(() => {
    const source = axios.CancelToken.source();
  
    const fetchChats = async () => {
      if (!selectedItem) {
        setChats([]);
        setLoading(false);
        return;
      }
  
      setLoading(true);
      setError("");
  
      try {
        const response = await axios.get(
          `${api}/get_interactions?client_id=${client_id}&email=${selectedItem}`,
          { cancelToken: source.token }
        );
        // console.log("Raw response data:", response.data);
  
        // If the API returns an array, use it directly.
        // Otherwise, check if it is an object with a property containing the array.
        const parsedData = Array.isArray(response.data)
          ? response.data 
          : response.data.chats || [];
        // console.log("Parsed chats:", parsedData);
  
        setChats(parsedData);

        if (isEditMode) {
          if (testMessage === "Customer") {
            const emptyMessage: ChatMessage = {
              timestamp: new Date().toISOString(),
              channel: "new lead",
              details: "Edit Message",
              notes: "received",
              reasoning: "Empty Message",
            };
            setChats([...parsedData, emptyMessage]);
          } else if (testMessage === "KindredPM") {
            const emptyMessage: ChatMessage = {
              timestamp: new Date().toISOString(),
              channel: "email",
              details: "Edit Message",
              notes: "sent",
              reasoning: "Empty Message",
            };
            setChats([...parsedData, emptyMessage]);
          }
        }
        
        setLoading(false);
        scrollToBottom();
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error("Error fetching chats:", err);
          setError("Failed to load chats.");
          setLoading(false);
        }
      }
    };
  
    fetchChats();
  
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [selectedItem, api, client_id, isEditMode, testMessage]);

  const handleRender = async () => {
    console.log("handleRender");
    try {
      const payload = {
        email: selectedItem,
        client_id: client_id
      };

      // 서버에 메시지 전송
      await axios.post(
        `${api}/rerender_conversation`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      alert("Please wait a minute as KindredPM comes up with an answer");
    }
    catch (err) {
      console.error("Error sending message:", err);
      setError("Failed to send message.");
    }
  }
  
  return (
    <div className="chat-wrapper">
      {selectedItem ? (
        <div className="chats-content">
          {/* Chat Title */}
          <h2 className="chats-title">
            {selectedItem}'s Chat History
            <div className="edit-mode">
              {isEditMode ? (
                <div className="edit-buttons">
                  <div className="button-row">
                    <button className="send-button red" onClick={() => { setIsEditMode(false); setTestMessage('') }}>Cancel</button>
                    <button className="send-button" onClick={handleRender}>Render</button>
                  </div>
                  <div className="button-row">
                    <button className="send-button" onClick={() => setTestMessage("Customer")}>Customer</button>
                    <button className="send-button" onClick={() => setTestMessage("KindredPM")}>KindredPM</button>
                  </div>
                </div>
              ) : (
                <button className="send-button" onClick={() => setIsEditMode(true)}>Edit</button> 
              )}
              
            </div>
          </h2>
          {/* Chat Messages */}
          <div className="chats-messages">
            {loading && <p className="loading-message">Loading chats...</p>}
            {error && <p className="error-message">{error}</p>}
            {!loading && !error && chats.length > 0 ? (
              <ChatList
                chats={chats}
                expandedMessages={expandedMessages}
                onToggleMessage={toggleMessage}
                onDeleteChat={handleDeleteChat}
                onUpdateChatDetails={handleUpdateChatDetails}
                isEditMode={isEditMode}
              />
            ) : (
              !loading &&
              !error && <p className="no-chats-message">No chats available for the selected client.</p>
            )}
            <div ref={messagesEndRef} />
          </div>
          {/* Chat Input Area */}
          <div className="chat-input-container">
            <div className="channel-selector">
              <label htmlFor="channel-select" className="channel-label">Channel:</label>
              <select
                id="channel-select"
                className="channel-select"
                value={selectedChannel}
                onChange={handleChannelChange}
                aria-label="Select communication channel"
              >
                <option value="sms">sms</option>
                <option value="email">email</option>
                {/* Add more channels as needed */}
              </select>
            </div>
            
            <input
              type="text"
              className="chat-input"
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              aria-label="Type your message"
            />
            <button
              className="send-button"
              onClick={handleSendMessage}
              aria-label="Send message"
            >
              Send
            </button>
          </div>
        </div>
      ) : (
        <div className="no-client-selected">
          <p>Select a client to view chat history.</p>
        </div>
      )}
    </div>
  );
};

export default Chats;
