// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/ListItem.css */

.list-item {
  display: flex;
  flex-direction: column;
  padding: 5px 400px;
  margin-bottom: 8px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.list-item.dragging {
  background-color: #e0f7fa;
}

.item-content {
  width: 100%;
  font-weight: bold;
  margin-bottom: 8px;
}

.item-description textarea {
  width: 100%;
  min-height: 90px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ListItem.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":["/* src/ListItem.css */\n\n.list-item {\n  display: flex;\n  flex-direction: column;\n  padding: 5px 400px;\n  margin-bottom: 8px;\n  background-color: #ffffff;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  transition: background-color 0.2s ease;\n}\n\n.list-item.dragging {\n  background-color: #e0f7fa;\n}\n\n.item-content {\n  width: 100%;\n  font-weight: bold;\n  margin-bottom: 8px;\n}\n\n.item-description textarea {\n  width: 100%;\n  min-height: 90px;\n  padding: 8px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  resize: vertical;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
