// src/pages/TextContents.tsx
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './TextContents.css';

interface TextContentsProps {
  api: string;
  client_id: string;
}

const TextContents: React.FC<TextContentsProps> = ({ api, client_id }) => {
  const [textContents, setTextContents] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [editedTextContents, setEditedTextContents] = useState<any>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const GETAPI = `${api}/get_prompts?client_id=${client_id}`;
  const POSTAPI = `${api}/post_prompts?client_id=${client_id}`;

  // Refs for each textarea
  const textareaRefs = useRef<{ [key: string]: HTMLTextAreaElement | null }>({});

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(GETAPI);
        setTextContents(response.data);
        setEditedTextContents(response.data);
        // After setting prompts, adjust textarea heights
        setTimeout(() => {
          Object.keys(response.data).forEach((key) => {
            autoResize(key, response.data[key]);
          });
        }, 0);
      } catch (err) {
        console.error('Error fetching client_id:', err);
        setError('Failed to load client_id.');
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, [GETAPI]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEditedTextContents({
      ...editedTextContents,
      [name]: value,
    });
    autoResize(name, value);
  };

  const handleSave = async () => {
    try {
      await axios.post(POSTAPI, editedTextContents);
      alert('Saved successfully');
      setIsEditing(false); // Save 후 다시 비활성화 상태로 전환
    } catch (err) {
      console.error('Error saving prompts:', err);
      alert('Failed to save prompts.');
    }
  };

  const handleEditToggle = () => {
    // Edit 버튼 클릭 시 편집 모드 토글
    setIsEditing((prev) => !prev);
  };

  // Function to auto-resize textarea
  const autoResize = (key: string, value: string) => {
    const textarea = textareaRefs.current[key];
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set to scrollHeight
    }
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="text-container">
      <div className="button-container">
        <button className="save-button" onClick={handleSave}>Apply</button>
        <button 
          className="edit-button"
          onClick={handleEditToggle}
        >
          {isEditing ? 'Done' : 'Edit'}
        </button>
      </div>
      {textContents && (
        <div className="text-form">
          {Object.keys(textContents).map((key) => (
            <div className="text-field" key={key}>
              <label className="text-label" htmlFor={key}>{key}:</label>
              <textarea
                className="text-input"
                id={key}
                name={key}
                value={editedTextContents[key]}
                onChange={handleChange}
                ref={(el) => (textareaRefs.current[key] = el)}
                rows={1}
                disabled={!isEditing}  // isEditing이 false일 때 비활성화
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TextContents;
