// src/App.tsx
import React, { useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import NewLeads from './pages/NewLeads';
import Properties from './pages/Properties';
import ManualReview from './pages/ManualReview';
import FileUploader from './pages/FileUploader';
import Prompts from './pages/Prompts';
import Settings from './pages/Settings';
import Dashboard from './pages/Dashboard';
import RepairTickets from './pages/RepairTickets';
import Vendors from './pages/Vendors';
import PropertiesAndTenants from './pages/PropertiesAndTenants';
import Logout from './pages/Logout';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedLayout from './components/ProtectedLayout';
import houseImage from './assets/house2.jpg';
import './Neumorphism.css';

const App: React.FC = () => {
  const api: string = useMemo(() => process.env.REACT_APP_FLASKSERVER || '', []);
  
  if (!api) {
    console.error('FLASKSERVER environment variable is not set');
  }

  const routes = useMemo(() => (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Login />} />

      {/* Protected Routes */}
      <Route element={<ProtectedRoute />}>
        <Route element={<ProtectedLayout />}>
          <Route path="/new-leads" element={<NewLeads api={api}/>} />
          <Route path="/properties" element={<Properties api={api}/>} />
          <Route path="/manual-review" element={<ManualReview api={api}/>} />
          <Route path="/upload-documents" element={<FileUploader api={api}/>} />
          <Route path="/prompts" element={<Prompts api={api}/>} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/repair-tickets" element={<RepairTickets api={api}/>} />
          <Route path="/vendors" element={<Vendors api={api}/>} />
          <Route path="/propertiesAndTenants" element={<PropertiesAndTenants/>} />
          <Route path="/logout" element={<Logout />} />
          {/* Redirect any unknown routes to /new-leads */}
          <Route path="*" element={<Navigate to="/new-leads" replace />} />
        </Route>
      </Route>
    </Routes>
  ), [api]);

  return routes;
};

export default App;