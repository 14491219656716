// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './contexts/AuthContext';
import { UnReadCountProvider } from './contexts/UnReadCountContext';
import { SocketProvider } from './contexts/SocketContext';
import Socket from './socket';

// Replace with your actual Google Client ID
const GOOGLE_clientID = process.env.REACT_APP_GOOGLE_OAUTH_API_KEY || '';

// Initialize the root using ReactDOM.createRoot
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

//TODO: src/in
// Diary 컴포넌트가 마운트 될 때마다 title 바꾸기
// useEffect(()=>{
//   const titleElement = document.getElementsByTagName('title')[0]; // 가져온 title 태그 요소중 첫번째꺼 지정 (근데 원래 하나임)
//   titleElement.innerHTML = `감정 일기장 - ${id}번 일기`; // 감정 일기장 - ${parseInt(id) + 1}번 일기 => 1번부터 시작하게 하기
// },[]);

// Render the application
root.render(
  <>
  {/* <React.StrictMode> */}
    {/* <GoogleOAuthProvider clientId={GOOGLE_clientID}> */}
      <AuthProvider>
        <SocketProvider>
          <UnReadCountProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </UnReadCountProvider>
        </SocketProvider>
    </AuthProvider>
    {/* </GoogleOAuthProvider> */}
  {/* </React.StrictMode> */}
  </>
);
