// src/contexts/UnReadCountContext.tsx
import React, { createContext, useState, ReactNode, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';

// 컨텍스트의 타입 정의
interface UnReadCountNewLeadsContextType {
  isReadCount: number;
  setUnReadCount: React.Dispatch<React.SetStateAction<number>>;
  isReadCountFlag: number;
  setUnReadCountFlag: React.Dispatch<React.SetStateAction<number>>;
}
const defaultUnReadCountNewLeadsContext: UnReadCountNewLeadsContextType = {
  isReadCount: 0,
  setUnReadCount: () => {},
  isReadCountFlag: 0,
  setUnReadCountFlag: () => {},
};

// 컨텍스트 생성
export const UnReadCountContext = createContext<UnReadCountNewLeadsContextType>(defaultUnReadCountNewLeadsContext);

// 프로바이더의 props 타입 정의
interface UnReadCountProviderProps {
  children: ReactNode;
}

// 프로바이더 컴포넌트
export const UnReadCountProvider: React.FC<UnReadCountProviderProps> = ({ children }) => {
  // const api = process.env.REACT_APP_API_SERVER;
  // const { user, logout } = useContext(AuthContext);
  const [isReadCount, setUnReadCount] = useState<number>(0);
  const [isReadCountFlag, setUnReadCountFlag] = useState<number>(0);
  // const [loadingInbox, setLoadingInbox] = useState<boolean>(false);
  // const [loadingFlagged, setLoadingFlagged] = useState<boolean>(false);
  // const [errorInbox, setErrorInbox] = useState<string | null>(null);
  // const [errorFlagged, setErrorFlagged] = useState<string | null>(null);

  // useEffect(() => {
  //   console.log('Navbar user:', user);
  //   if (!user) return;

  //   const inboxEndpoint = `${api}/count_unread_inbox?client_id=${user.id}`;
  //   const flaggedEndpoint = `${api}/count_unread_flagged?client_id=${user.id}`;

  //   const fetchInbox = async () => {
  //     setLoadingInbox(true);
  //     setErrorInbox(null);
  //     try {
  //       const response = await axios.get(inboxEndpoint);
  //       console.log('Inbox Response:', response.data);
  //       if (typeof response.data.data === 'number') {
  //         setUnReadCount(response.data.data);
  //       } else {
  //         setErrorInbox('Invalid data format for inbox count.');
  //       }
  //     } catch (err) {
  //       console.error('Error fetching inbox count:', err);
  //       setErrorInbox('Failed to load inbox count.');
  //     } finally {
  //       setLoadingInbox(false);
  //     }
  //   };

  //   const fetchFlagged = async () => {
  //     setLoadingFlagged(true);
  //     setErrorFlagged(null);
  //     try {
  //       const response = await axios.get(flaggedEndpoint);
  //       console.log('Flagged Response:', response.data);
  //       if (typeof response.data.data === 'number') {
  //         setUnReadCountFlag(response.data.data);
  //       } else {
  //         setErrorFlagged('Invalid data format for flagged count.');
  //       }
  //     } catch (err) {
  //       console.error('Error fetching flagged count:', err);
  //       setErrorFlagged('Failed to load flagged count.');
  //     } finally {
  //       setLoadingFlagged(false);
  //     }
  //   };
  
  //   fetchInbox();
  //   fetchFlagged();
  // }
  // , [api, user, isReadCount, isReadCountFlag]);

  

  return (
    <UnReadCountContext.Provider value={{ isReadCount, setUnReadCount, isReadCountFlag, setUnReadCountFlag }}>
      {children}
    </UnReadCountContext.Provider>
  );
};
