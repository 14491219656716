// src/components/Listing_clientItem.tsx
import React, { useContext } from 'react';
import { StarIcon } from '@heroicons/react/24/solid';
import { UnReadCountContext } from '../contexts/UnReadCountContext';

interface Item {
  email: string;
  isRead: boolean;
  isStar?: boolean;
  isCheck?: boolean;
  timestamp: string;
  details?: string;
}

interface ClientItemProps {
  item: Item;
  selectedItem: Item;
  onClick: (email: string) => void;                 // idx -> email
  onStar: (email: string, star: boolean) => void;   // idx -> email
  isChecked: boolean;
  onCheck: (email: string, checked: boolean) => void;
  setUnReadCount: React.Dispatch<React.SetStateAction<number>>;
}

const ClientItem: React.FC<ClientItemProps> = React.memo(
  ({ item, selectedItem, onClick, onStar, isChecked, onCheck, setUnReadCount }) => {

    // 리스트 행 클릭: 읽음 처리
    const handleRowClick = () => {
      // 클릭 시 onClick(email) 호출
      onClick(item.email);
      if (!item.isRead) {
        setUnReadCount(prev => prev - 1);
      }
    };

    // 아이콘 클릭 시 isStar 토글
    const handleIconClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      e.stopPropagation();
      onStar(item.email, !item.isStar); 
    };

    // 체크박스 변경
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      onCheck(item.email, e.target.checked);
    };

    return (
      <li
        className={`client-item ${
          selectedItem.email === item.email
            ? 'selected'
            : !item.isRead
            ? 'isRead'
            : ''
        }`}
        onClick={handleRowClick}
      >
        {/* 체크박스 */}
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="checkBox"
        />

        {/* 아이콘 (isStar 토글) */}
        <StarIcon
          onClick={handleIconClick}
          className={`client-icon ${item.isStar ? 'star' : ''}`}
        />

        <div className="client-info">
          <div className="client-list item">
            <span className="client-name">{item.email}</span>
            <span className="client-name">
              {item.details && item.details.slice(0, 30) + '...'}
            </span>
            <span className="client-name">{item.timestamp}</span>
          </div>
          <div className={`circle-indicator ${item.isRead ? 'read' : ''}`}></div>
        </div>
      </li>
    );
  }
);

export default ClientItem;
