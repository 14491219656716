// src/components/ChatList.tsx
import React from 'react';
import ChatItem from './ChatItem';

interface ChatMessage {
  timestamp: string;
  channel: string;
  details: string;
  notes: string;
  reasoning?: string;
}

interface ChatListProps {
  chats: ChatMessage[];
  expandedMessages: Set<number>;
  onToggleMessage: (index: number) => void;
  onDeleteChat: (index: number) => void;
  onUpdateChatDetails: (index: number, newDetails: string) => void;
  isEditMode: boolean;
}

const ChatList: React.FC<ChatListProps> = ({
  chats,
  expandedMessages,
  onToggleMessage,
  onDeleteChat,
  onUpdateChatDetails,
  isEditMode
}) => {
  return (
    <>
      {chats.map((chat, index) => (
        <ChatItem
          key={`${chat.timestamp}-${index}`}
          chat={chat}
          index={index}
          isExpanded={expandedMessages.has(index)}
          onToggleMessage={onToggleMessage}
          onDeleteChat={onDeleteChat}
          onUpdateChatDetails={onUpdateChatDetails}
          isEditMode={isEditMode}
        />
      ))}
    </>
  );
};

export default ChatList;
