import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import logo from '../assets/KindredAI_logo_mini.png';
import './Chats.css'; 

interface ChatMessage {
  timestamp: string;
  channel: string;
  details: string;
  notes: string;
  reasoning?: string;
}

interface ChatItemProps {
  chat: ChatMessage;
  index: number;
  isExpanded: boolean;
  onToggleMessage: (index: number) => void;
  onDeleteChat: (index: number) => void;
  onUpdateChatDetails: (index: number, newDetails: string) => void;
  isEditMode: boolean;
}

const ChatItem: React.FC<ChatItemProps> = ({
  chat,
  index,
  isExpanded,
  onToggleMessage,
  onDeleteChat,
  onUpdateChatDetails,
  isEditMode
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedDetails, setEditedDetails] = useState(chat.details);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const detailsRef = useRef<HTMLParagraphElement | null>(null);
  const [detailsWidth, setDetailsWidth] = useState<number | null>(null);

  const getSender = (notes: string): "agent" | "client" => {
    return notes.toLowerCase() === "sent" ? "agent" : "client";
  };

  const sender = getSender(chat.notes);

  useEffect(() => {
    // textarea height auto-adjust
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  }, [editedDetails, isEditing]);

  const handleEdit = () => {
    if (!isEditing) {
      // Edit 모드로 들어가기 전 chat-details의 너비를 측정
      if (detailsRef.current) {
        setDetailsWidth(detailsRef.current.offsetWidth);
      }
    } else {
      // Edit 모드 종료 시 save
      onUpdateChatDetails(index, editedDetails);
    }
    setIsEditing(!isEditing);
  };

  return (
    <div className="chat-item" key={`${chat.timestamp}-${index}`}>
      {sender === "agent" ? (
        <img
          src={logo}
          alt="KindredPM Logo"
          className="client-icon agent"
        />
      ) : (
        <UserCircleIcon className="client-icon client" />
      )}
      <div
        className={`chat-bubble ${sender === "agent" ? "agent" : "client"}`}
        onClick={() => sender === "agent" && onToggleMessage(index)}
        style={sender === "agent" ? { cursor: 'pointer' } : {}}
      >
        <div className="chat-content">
          {!isEditing ? (
            <p className={`chat-details ${sender}`} ref={detailsRef}>
              {chat.details.split(/(https:\/\/\S+)/g).map((part, i) =>
                part.startsWith('https://') ? (
                  <a
                    key={i}
                    href={part}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'blue', textDecoration: 'underline' }}
                    onMouseOver={(e) => e.currentTarget.style.textDecoration = 'none'}
                    onMouseOut={(e) => e.currentTarget.style.textDecoration = 'underline'}
                  >
                    {part}
                  </a>
                ) : (
                  part
                )
              )}
            </p>
          ) : (
            <textarea
              className="edit-input"
              value={editedDetails}
              onChange={(e) => setEditedDetails(e.target.value)}
              ref={textareaRef}
              style={{ width: detailsWidth ? `${detailsWidth}px` : 'auto' }}
            />
          )}

          {sender === "agent" && isExpanded && chat.reasoning && (
            <p className="chat-reasoning">
              {chat.reasoning}
            </p>
          )}
          <span className="chat-timestamp">
            {chat.channel} {new Date(chat.timestamp).toLocaleString()}
          </span>
        </div>
      </div>
      <div className={`chat-actions ${sender === "agent" ? "agent" : "client"}`}>
      {isEditMode && (
        <>
          <button className="chat-delete-button" onClick={() => onDeleteChat(index)}>Delete</button>
          <button className="chat-edit-button" onClick={handleEdit}>{isEditing ? "Save" : "Edit"}</button>
        </>
      )}
      </div>
    </div>
  );
};

export default ChatItem;
