// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/DraggableList.css */

.droppable-list {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 16px;
  background-color: #f4f5f7;
  border-radius: 8px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f4f5f7;
}

.droppable-list::-webkit-scrollbar {
  width: 8px;
}

.droppable-list::-webkit-scrollbar-track {
  background: #f4f5f7;
}

.droppable-list::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
  border: 2px solid #f4f5f7;
}

.droppable-list > .list-item + .list-item {
  margin-top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DraggableList.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;EACrB,6BAA6B;AAC/B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB","sourcesContent":["/* src/DraggableList.css */\n\n.droppable-list {\n  width: 100%;\n  height: 100%;\n  margin: 0 auto;\n  padding: 16px;\n  background-color: #f4f5f7;\n  border-radius: 8px;\n  overflow-y: auto;\n  scrollbar-width: thin;\n  scrollbar-color: #888 #f4f5f7;\n}\n\n.droppable-list::-webkit-scrollbar {\n  width: 8px;\n}\n\n.droppable-list::-webkit-scrollbar-track {\n  background: #f4f5f7;\n}\n\n.droppable-list::-webkit-scrollbar-thumb {\n  background-color: #888;\n  border-radius: 4px;\n  border: 2px solid #f4f5f7;\n}\n\n.droppable-list > .list-item + .list-item {\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
