// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/Neumorphism.css */
root {

}
/* Neumorphic Container */
.app-container {
  /* display: flex; */
  flex-direction: column;
  /* background-color: rgba(240, 245, 245, 1); */
  background-color: rgb(255, 255, 255);
  /* padding-top: 20px; */
  /* padding-left: 20px;
  padding-right: 20px; */
  height: 100vh;
}

/* Neumorphic Main Content */
.main-content {
  height: 97vh;
  display: flex;
  /* flex: 1; */
}

/* Neumorphic Navbar (Assuming Navbar component has its own styling) */
/* You may need to update the Navbar component similarly */

/* Example Neumorphic Button */
.neumorphic-button {
  border: none;
  border-radius: 15px;
  padding: 10px 20px;
  background-color: var(--element-color);
  color: var(--text-color);
  font-size: 1rem;
  cursor: pointer;
  box-shadow:
    5px 5px 10px var(--shadow-dark),
    -5px -5px 10px var(--shadow-light);
  transition: all 0.3s ease;
}

.neumorphic-button:active {
  box-shadow:
    inset 5px 5px 10px var(--shadow-dark),
    inset -5px -5px 10px var(--shadow-light);
}

.neumorphic-button:hover {
  box-shadow:
    7px 7px 14px var(--shadow-dark),
    -7px -7px 14px var(--shadow-light);
}

/* Additional Neumorphic Elements */
/* You can define more classes as needed, such as for inputs, cards, etc. */`, "",{"version":3,"sources":["webpack://./src/Neumorphism.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;;AAEA;AACA,yBAAyB;AACzB;EACE,mBAAmB;EACnB,sBAAsB;EACtB,8CAA8C;EAC9C,oCAAoC;EACpC,uBAAuB;EACvB;wBACsB;EACtB,aAAa;AACf;;AAEA,4BAA4B;AAC5B;EACE,YAAY;EACZ,aAAa;EACb,aAAa;AACf;;AAEA,sEAAsE;AACtE,0DAA0D;;AAE1D,8BAA8B;AAC9B;EACE,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,sCAAsC;EACtC,wBAAwB;EACxB,eAAe;EACf,eAAe;EACf;;sCAEoC;EACpC,yBAAyB;AAC3B;;AAEA;EACE;;4CAE0C;AAC5C;;AAEA;EACE;;sCAEoC;AACtC;;AAEA,mCAAmC;AACnC,2EAA2E","sourcesContent":["/* src/Neumorphism.css */\nroot {\n\n}\n/* Neumorphic Container */\n.app-container {\n  /* display: flex; */\n  flex-direction: column;\n  /* background-color: rgba(240, 245, 245, 1); */\n  background-color: rgb(255, 255, 255);\n  /* padding-top: 20px; */\n  /* padding-left: 20px;\n  padding-right: 20px; */\n  height: 100vh;\n}\n\n/* Neumorphic Main Content */\n.main-content {\n  height: 97vh;\n  display: flex;\n  /* flex: 1; */\n}\n\n/* Neumorphic Navbar (Assuming Navbar component has its own styling) */\n/* You may need to update the Navbar component similarly */\n\n/* Example Neumorphic Button */\n.neumorphic-button {\n  border: none;\n  border-radius: 15px;\n  padding: 10px 20px;\n  background-color: var(--element-color);\n  color: var(--text-color);\n  font-size: 1rem;\n  cursor: pointer;\n  box-shadow:\n    5px 5px 10px var(--shadow-dark),\n    -5px -5px 10px var(--shadow-light);\n  transition: all 0.3s ease;\n}\n\n.neumorphic-button:active {\n  box-shadow:\n    inset 5px 5px 10px var(--shadow-dark),\n    inset -5px -5px 10px var(--shadow-light);\n}\n\n.neumorphic-button:hover {\n  box-shadow:\n    7px 7px 14px var(--shadow-dark),\n    -7px -7px 14px var(--shadow-light);\n}\n\n/* Additional Neumorphic Elements */\n/* You can define more classes as needed, such as for inputs, cards, etc. */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
