// src/components/Navbar.tsx
import React, { useState, useContext, useEffect, useMemo } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { UnReadCountContext } from '../contexts/UnReadCountContext';
import {
  ChatBubbleBottomCenterTextIcon,
  UsersIcon,
  FolderIcon,
  FlagIcon,
  ArrowUpTrayIcon,
  CommandLineIcon,
  HomeIcon,
  CogIcon,
  ChartBarIcon,
  ClipboardDocumentListIcon,
  ArrowLeftStartOnRectangleIcon
} from '@heroicons/react/24/outline';
import logo from '../assets/KPM_logo_black.png';
import './Navbar.css';

const Navbar: React.FC = () => {
  const { user, logout } = useContext(AuthContext);
  const { isReadCount, setUnReadCount, isReadCountFlag, setUnReadCountFlag } = useContext(UnReadCountContext);
  const [loadingInbox, setLoadingInbox] = useState<boolean>(false);
  const [loadingFlagged, setLoadingFlagged] = useState<boolean>(false);
  const [errorInbox, setErrorInbox] = useState<string | null>(null);
  const [errorFlagged, setErrorFlagged] = useState<string | null>(null);

  const api: string = useMemo(() => process.env.REACT_APP_FLASKSERVER || '', []);

  useEffect(() => {
    console.log('Navbar user:', user);
    if (!user) return;

    const inboxEndpoint = `${api}/count_unread_inbox?client_id=${user.id}`;

    const fetchInbox = async () => {
      setLoadingInbox(true);
      setErrorInbox(null);
      try {
        const response = await axios.get(inboxEndpoint);
        console.log('Inbox Response:', response.data);
        if (typeof response.data.data === 'number') {
          setUnReadCount(response.data.data);
        } else {
          setErrorInbox('Invalid data format for inbox count.');
        }
      } catch (err) {
        console.error('Error fetching inbox count:', err);
        setErrorInbox('Failed to load inbox count.');
      } finally {
        setLoadingInbox(false);
      }
    };
  
    fetchInbox();
  }
  , [api, user, isReadCount]);


  useEffect(() => {
    console.log('Navbar user:', user);
    if (!user) return;

    const flaggedEndpoint = `${api}/count_unread_flagged?client_id=${user.id}`;

    const fetchFlagged = async () => {
      setLoadingFlagged(true);
      setErrorFlagged(null);
      try {
        const response = await axios.get(flaggedEndpoint);
        console.log('Flagged Response:', response.data);
        if (typeof response.data.data === 'number') {
          setUnReadCountFlag(response.data.data);
        } else {
          setErrorFlagged('Invalid data format for flagged count.');
        }
      } catch (err) {
        console.error('Error fetching flagged count:', err);
        setErrorFlagged('Failed to load flagged count.');
      } finally {
        setLoadingFlagged(false);
      }
    };
  
    fetchFlagged();
  }
  , [api, user, isReadCountFlag]);

  const handleLogout = async () => {
    await logout();
  };

  const navAgents = [
    {
      role: "Leasing",
      subRole: [
        { name: 'New Leads', path: '/new-leads', icon: ChatBubbleBottomCenterTextIcon },
        { name: 'Properties', path: '/properties', icon: HomeIcon },
        { name: 'Manual Review', path: '/manual-review', icon: FlagIcon },
        { name: 'Upload Documents', path: '/upload-documents', icon: ArrowUpTrayIcon },
        { name: 'Prompts', path: '/prompts', icon: CommandLineIcon },
      ]
    },
    {
      role: "Maintenance",
      subRole: [
        { name: 'Dashboard', path: '/dashboard', icon: ChartBarIcon },
        { name: 'Repair Tickets', path: '/repair-tickets', icon: FolderIcon },
        { name: 'Vendors', path: '/vendors', icon: UsersIcon },
        { name: 'Properties & Tenants', path: '/propertiesAndTenants', icon: ClipboardDocumentListIcon },
        { name: 'Settings', path: '/settings', icon: CogIcon },
        { name: 'Logout', path: '/logout', icon: ArrowLeftStartOnRectangleIcon }
      ]
    }
  ];

  return (
    <div className="navbar-container">
      {/* Neumorphic Logo Section */}
      <div className="logo-container">
        <img src={logo} alt="KindredPM Logo" className="logo-image" />
      </div>

      {/* Navigation Links */}
      <nav className="nav-links">
        {navAgents.map((agent) => (
          <div key={agent.role}> {/* key 추가 */}
            <div className='role-item'>
              <h3>{agent.role}</h3>
            </div>
            {agent.subRole.map((item) => (
              <NavLink
                key={item.name}
                to={item.path}
                className={({ isActive }) =>
                  `nav-item ${isActive ? 'active' : ''}`
                }
                onClick={item.name === 'Logout' ? handleLogout : undefined} // Logout 클릭 시 handleLogout 호출
              >
                {/* Icon */}
                <item.icon className="nav-icon" />
                <span className="nav-text">{item.name}</span>
                {item.name === 'New Leads' && <div className="nav-isRead">{isReadCount}</div>}
                {item.name === 'Manual Review' && <div className="nav-isRead">{isReadCountFlag}</div>}
              </NavLink>
            ))}
          </div>
        ))}
      </nav>
    </div>
  );
};

export default Navbar;
