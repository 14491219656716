// src/components/Properties.tsx
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import Listing from '../components/Listing';
import './Properties.css'; // Properties.css 임포트
import VendorDetail from '../components/VendorDetail';
import '../components/Listing.css';
import '../components/VendorDetail.css'; // Import VendorDetail styles

export interface Vendor {
  "phone number": string;
  email: string;
  address: string;
  specialty: string[];
  notes: string;
  description: string;
  vendor: string;
}

interface _Vendors {
  [vendorName: string]: Vendor;
}

interface API {
  api: string;
}

interface tVendorDetail extends Vendor {
  vendor: string;
}

const Vendors: React.FC<API> = ({ api }) => {
  const { user } = useContext(AuthContext);
  const [vendors, setVendors] = useState<_Vendors>({});
  const [selectedVendor, setSelectedVendor] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedProperty, setSelectedProperty] = useState<tVendorDetail | null>(null);
  const API_URL = user ? `${api}/preferred_vendors?client_id=${user.id}` : '';

  // Handle vendor selection
  function handleSelectedVendors(vendorName: string) {
    setSelectedVendor(vendorName);
    const tmp = { ...vendors[vendorName], vendor: vendorName };
    setSelectedProperty(tmp);
    console.log(tmp);
  }

  // Handle vendor updates from VendorDetail
  const handleUpdateVendor = (updatedVendor: tVendorDetail) => {
    setVendors(prevVendors => ({
      ...prevVendors,
      [updatedVendor.vendor]: {
        ...prevVendors[updatedVendor.vendor],
        address: updatedVendor.address,
        email: updatedVendor.email,
        "phone number": updatedVendor["phone number"],
        specialty: updatedVendor.specialty,
        description: updatedVendor.description,
        notes: updatedVendor.notes,
      }
    }));
    setSelectedProperty(updatedVendor);
  };

  // Fetch vendors from the API when the component mounts
  useEffect(() => {
    const fetchVendors = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get<_Vendors>(API_URL);
        setVendors(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching vendors:', err);
        setError('Failed to load vendors.');
        setLoading(false);
      }
    };

    if (API_URL) {
      fetchVendors();
    }
  }, [API_URL]);

  return (
    <div className="responsive-flex">
      {/* Vendor List */}
      <Listing
        items={Object.keys(vendors)}
        title="Vendors"
        loading={loading}
        error={error}
        selectedItem={selectedVendor}
        handleSelectedItem={handleSelectedVendors}
      />

      {/* VendorDetail */}
      {selectedProperty ? (
        <div className="content-container">
          <div className="content-header" style={{ cursor: 'pointer' }}>
            <h2 className="content-title">Vendors</h2>
            <div className="property-section">
              <VendorDetail selectedProperty={selectedProperty} onUpdate={handleUpdateVendor} />
            </div>
          </div>
        </div>
      ) : (
        <div className="no-selection">
          {loading ? "Loading..." : error ? error : "Select a vendor to see details."}
        </div>
      )}
    </div>
  );
};

export default Vendors;
