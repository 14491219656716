// src/contexts/SocketContext.tsx
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { io, Socket } from 'socket.io-client';

type SocketContextValue = Socket | null;
const SocketContext = createContext<SocketContextValue>(null);

/**
 * 전역 소켓 연결을 제공하는 Provider 컴포넌트
 */
export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // 환경변수나 필요한 url을 가져옵니다.
  const api = useMemo(() => process.env.REACT_APP_FLASKSERVER || '', []);

  // 소켓 인스턴스를 한 번만 생성
  const socket = useMemo(() => {
    return io(api, { transports: ['websocket'] });
  }, [api]);

  useEffect(() => {
    // 필요하면 여기서 글로벌 이벤트를 달아줄 수 있음
    socket.on('connect', () => {
      console.log('Global Socket connected:', socket.id);
    });

    // 컴포넌트 언마운트 시 소켓 연결 해제
    return () => {
      console.log('Global Socket disconnected');
      socket.disconnect();
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

/**
 * 소켓을 편리하게 가져다 쓸 수 있는 Hook
 */
export const useSocket = (): Socket => {
  const socket = useContext(SocketContext);
  if (!socket) {
    throw new Error('useSocket must be used within a <SocketProvider>');
  }
  return socket;
};
