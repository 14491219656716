// src/components/JsonContents.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Editor, { OnMount } from '@monaco-editor/react';
import './JsonContents.css';

interface JsonContentsProps {
  api: string;
  client_id: string;
}

const JsonContents: React.FC<JsonContentsProps> = ({ api, client_id }) => {
  const [contents, setContents] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editValues, setEditValues] = useState<{ [key: string]: string }>({});

  const GETAPI = `${api}/get_documents?client_id=${client_id}`;
  const POSTAPI = `${api}/post_documents?client_id=${client_id}`;

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await axios.get(GETAPI);
        setContents(response.data);
      } catch (error) {
        console.error('Error fetching JsonContents:', error);
        setError('Error fetching JsonContents. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    fetchProperty(); 
  }, [GETAPI]);

  useEffect(() => {
    if (contents && !isEditing) {
      const newEditValues: { [key: string]: string } = {};
      for (const [key, value] of Object.entries(contents)) {
        if (key !== "documents_list.json") {
          newEditValues[key] = JSON.stringify(value, null, 4);
        }
      }
      setEditValues(newEditValues);
    }
  }, [contents, isEditing]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error && !isEditing) {
    return <div className="error">{error}</div>;
  }

  const handleSave = async () => {
    try {
      await axios.post(POSTAPI, contents);
      alert('Saved successfully');
      setIsEditing(false);
    } catch (err) {
      console.error('Error saving prompts:', err);
      alert('Failed to save prompts.');
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setError(null);
  };

  const handleDone = () => {
    try {
      const newContents: { [key: string]: any } = {};
      for (const [key, value] of Object.entries(contents)) {
        if (key !== "documents_list.json") {
          const editedText = editValues[key];
          if (editedText) {
            newContents[key] = JSON.parse(editedText);
          } else {
            newContents[key] = value;
          }
        } else {
          newContents[key] = value;
        }
      }
      setContents(newContents);
      setError(null);
      setIsEditing(false);
    } catch (err) {
      if (err instanceof Error) {
        setError(`JSON Parsing Error: ${err.message}`);
      } else {
        setError('Unknown Error');
      }
    }
  };

  const onEditorChange = (key: string, value?: string) => {
    if (value !== undefined) {
      setEditValues(prev => ({ ...prev, [key]: value }));
    }
  };

  const handleEditorMount: OnMount = (editor, monaco) => {
    // 여기서 onDidContentSizeChange 이벤트를 제거하거나 레이아웃 호출을 하지 않는다.
    // editor.onDidContentSizeChange(() => {
    //   const contentHeight = editor.getContentHeight();
    //   editor.layout({ width: editor.getLayoutInfo().width, height: contentHeight });
    // });
    //
    // const initialHeight = editor.getContentHeight();
    // editor.layout({ width: editor.getLayoutInfo().width, height: initialHeight });
    // automaticLayout 옵션을 믿고 별도 레이아웃 호출은 하지 않는다.
  };

  return (
    <div className="json-editor-container">
      <div className="button-container">
        <button className="save-button" onClick={handleSave}>Apply</button>
        {!isEditing && (
          <button
            className="edit-button"
            onClick={handleEdit}
          >
            Edit
          </button>
        )}
        {isEditing && (
          <button
            className="edit-button"
            onClick={handleDone}
          >
            Done
          </button>
        )}
      </div>

      {error && (
        <div className="json-editor-error">
          {error}
        </div>
      )}

      {contents && Object.entries(contents).map(([key]) => (
        key !== "documents_list.json" ? (
          <div className="json-field" key={key}>
            <strong>{key}:</strong>
            {isEditing ? (
              <div className="json-editor-monaco">
                <Editor
                  language="json"
                  value={editValues[key]}
                  onChange={(value) => onEditorChange(key, value)}
                  options={{
                    tabSize: 2,
                    insertSpaces: true,
                    automaticLayout: true,
                    formatOnPaste: true,
                    formatOnType: true,
                    scrollBeyondLastLine: false,
                    minimap: { enabled: false },
                    wordWrap: 'on' // 줄바꿈 옵션 추가
                  }}
                  onMount={handleEditorMount}
                />
              </div>
            ) : (
              <pre className="json-editor-pre">
                {editValues[key]}
              </pre>
            )}
          </div>
        ) : null
      ))}
    </div>
  );
};

export default JsonContents;
