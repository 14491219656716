// src/components/Properties.tsx
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import './Properties.css'; // Properties.css 임포트
import Listing from '../components/Listing';
import PropertyDetail from '../components/PropertyDetail';
import Chats from '../components/Chats';
import Summary from '../components/Summary';
import { useSearchParams } from 'react-router-dom';

interface Property {
  [key: string]: string;
}

interface API {
  api: string;
}

const Properties: React.FC<API> = ({ api }) => {
  const { user } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const address = searchParams.get('address') || '';

  const [properties, setProperties] = useState<Property>();
  const [selectedProperty, setSelectedProperty] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedEmail, setSelectedEmail] = useState<string>('');
  const [emails, setEmails] = useState<string[]>([]);

  function handleSelectedProperty(property: string) {
    setSelectedProperty(property);
  }

  const handleClosePopup = () => {
    setSelectedEmail('');
  };

  // Fetch properties from the API when the component mounts
  useEffect(() => {
    if (!user || !api) return;

    const fetchProperties = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${api}/getproperties?client_id=${user.id}`);
        setProperties(response.data);
      } catch (err) {
        console.error('Error fetching Properties:', err);
        setError('Failed to load Properties.');
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, [user, api]);

  // Memoize sorted property keys to prevent unnecessary re-renders
  const sortedPropertyKeys = useMemo(() => {
    return properties ? Object.keys(properties).sort() : [];
  }, [properties]);

  // Fetch prospects when selectedProperty changes
  useEffect(() => {
    const fetchProspects = async () => {
      if (!user || !api || !properties || !selectedProperty) return;
      setError(null);
      try {
        const kindredID = properties[selectedProperty];
        const response = await axios.get(`${api}/get_prospects?client_id=${user.id}&Kindred_ID=${kindredID}`);
        setEmails(response.data);
      } catch (err) {
        console.error('Error fetching Prospects:', err);
        setError('Failed to load Prospects.');
      } finally {
        setLoading(false);
      }
    };

    fetchProspects();
  }, [user, api, properties, selectedProperty]);

  // Handle selected email for Chats/Summary
  const handleSelectedEmail = (email: string) => {
    setSelectedEmail(email);
  };

  // Set selectedProperty from URL address param
  useEffect(() => {
    if (address !== '') {
      if (properties && !(address in properties)) {
        //TODO: Check an alert or error message
        alert('Invalid address');
        return;
      }
      else if (properties && (address in properties)){
        console.log('Setting selected property from address:', address);
        handleSelectedProperty(address);
      }
    }
  }, [address, properties]);

  return (
    <div className="responsive-flex">
      {/* Client List */}
      {properties ? (
        <Listing
          items={sortedPropertyKeys} // 정렬된 키 전달
          title="Properties"
          loading={loading}
          error={error}
          selectedItem={selectedProperty}
          handleSelectedItem={handleSelectedProperty}
        />
      ) : (
        ''
      )}
      {/* Properties */}
      {properties && user && selectedProperty ? (
        <div className="content-container">
          <div className="content-header" style={{ cursor: 'pointer' }}>
            <h2 className="content-title">Prospects</h2>
            <div className="property-section Prospector">
              <div className="property-container">
                <ul className="client-list" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                  {emails.map((email) => (
                    <li key={email} className="email-item" onClick={() => handleSelectedEmail(email)}>
                      {email}
                    </li>
                  ))}
                </ul>
                {selectedEmail && (
                  <div className="popup">
                    <button onClick={handleClosePopup} className="close-button">
                      Exit
                    </button>
                    {/* Exit button here */}
                    <Chats api={api} client_id={user.id} selectedItem={selectedEmail} isEditModeParents={false} />
                    <Summary api={api} client_id={user.id} selectedItem={selectedEmail} />
                  </div>
                )}
              </div>
            </div>

            <h2 className="content-title">Property</h2>
            <div className="property-section Property">
              <PropertyDetail
                api={api}
                dir="get_property_details"
                client_id={user.id}
                Kindred_ID={properties[selectedProperty]}
              />
              <PropertyDetail
                api={api}
                dir="get_specific_policies"
                client_id={user.id}
                Kindred_ID={properties[selectedProperty]}
              />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Properties;
